import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { appointmentRequest, bookAppointmentGuest, bookappointmentSlotCheck, getPatientList } from "../../../services/AppointmentBook";
import { registerUserGuest } from "../../../services/auth";
import { OTPModal } from "../Otp";
import Cookies from 'js-cookie';
import configs from "../../../../../configs/apiConfigs";
import firebase from "firebase";
import { getCookie } from "../../../../../utils/global";
import { signInWithCustomToken } from "../../../../../utils/firebaseUtils";
import LoadingIndicator from "../../LoadingIndicator/LoadingIndicator";
import { fetchLoadingPending, fetchLoadingSuccess } from "../../../../../store/loadingIndicator/actions";
import { useDispatch } from "react-redux";
import Select from 'react-select';

export const GuestSession = ({
    hideModal, showGuestSession,
    doctor, blockSlotData, selectedSlot,
    bookAppointmentSuccess,
    asapAppt,
    selectedDate,
    hideTime
}: any) => {
    const [showGuestSessionConfirm, setShowGuestSessionConfirm] = useState(false);
    const [showGuest, setShowGuest] = useState(showGuestSession);
    const [guestDetails, setGuestDetails]: any = useState(null);
    const [apptRequiredASAP, setApptRequiredASAP] = useState(true);
    const [deptAppt, setDeptAppt] = useState(false);
    const [isShowOTPModal, setIsShowOTPModal] = useState(false);
    const [patientId, setPatientId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [guarantorId, setGuarantorId]: any = useState("");
    const [dependant, setDependant]: any = useState("");
    const dispatch = useDispatch();
    const { register, setError, handleSubmit, setValue, getValues, errors, trigger, control, watch } = useForm({
        defaultValues: {
            deptAppt: false,
            apptRequiredASAP: true,
            firstName: '',
            lastName: '',
            gfirstName: '',
            glastName: '',
            email: '',
            phone: '',
            dob: moment().subtract(1, 'day'),
            gender: 'Male',
            date: selectedSlot ? moment(selectedSlot.startdate) : moment(),
            starttime: selectedSlot ? moment(selectedSlot.startdate + " " + selectedSlot.startTime, "YYYY-MM-DD LT") : moment(),
            endtime: selectedSlot ? moment(selectedSlot.startdate + " " + selectedSlot.startTime, "YYYY-MM-DD LT").add(30, 'minutes') : moment().add(30, 'minutes'),
            appointmentSlot: ''
        }
    });
    const onRegisterClick = (values: any) => {
        console.log("values", values);
        setGuestDetails({
            ...values,
            appointmentSlot: values.appointmentSlot,
            etime: !apptRequiredASAP ?
                values.endtime :
                selectedSlot ? moment(selectedSlot.startdate + " " + selectedSlot.endTime, "YYYY-MM-DD LT") : moment(),
            endtime: !apptRequiredASAP ?
                moment(moment(values.starttime).add("minutes", doctor?.potentialAppointmentTypes[0].duration)) :
                selectedSlot ? moment(selectedSlot.startdate + " " + selectedSlot.endTime, "YYYY-MM-DD LT") : moment()
        });
        setValue('deptAppt', true);
        // setValue('apptRequiredASAP', true);
        setValue('firstName', '');
        setValue('lastName', '');
        setValue('gfirstName', '');
        setValue('glastName', '');
        setValue('email', '');
        setValue('phone', '');
        setValue('dob', moment().subtract(1, 'day'));
        setValue('gender', 'Male');
        setValue('date', moment());
        setValue('starttime', moment());
        setShowGuest(false);
        setShowGuestSessionConfirm(true);
        setDeptAppt(true);
        // setApptRequiredASAP(true);
    }
    const handleHideGuestSessionConfirm = () => {
        setShowGuestSessionConfirm(false);
        hideModal()
    }
    const onSignupClick = (values: any) => {
        console.log("onSignupClick", values, guestDetails);
        let requestedData = {
            fname: guestDetails.firstName,
            lname: guestDetails.lastName,
            email: guestDetails.email,
            phone: guestDetails.phone,
            dob: moment(guestDetails.dob).format('YYYY-MM-DD'),
            registeras: "normal",
            password: values.password,
            gender: guestDetails.gender,
            pcontactmethod: 'email,text',
            reminder: 'yes',
        }
        if (deptAppt) {
            Object.assign(requestedData, {
                isIndividual: false,
                request: {
                    paymode: 'cash,credit,card,cheque'
                },
                guarantor: {
                    fname: guestDetails.gfirstName,
                    lname: guestDetails.glastName,
                    email: guestDetails.email,
                    phone: guestDetails.phone,
                    dob: moment(guestDetails.dob).format('YYYY-MM-DD'),
                    registeras: "normal",
                    password: values.password,
                    gender: guestDetails.gender,
                }
            });
        } else {
            Object.assign(requestedData, {
                isIndividual: true,
                request: {
                    paymode: 'cash,credit,card,cheque'
                },
                guarantor: {
                    fname: getValues('firstName'),
                    lname: getValues('lastName'),
                    email: getValues('email'),
                    phone: getValues('phone'),
                    dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                    registeras: "normal",
                    password: getValues('password'),
                    gender: getValues('gender'),
                }
            });
        }
        setIsLoading(true);
        dispatch(fetchLoadingPending())
        registerUserGuest(requestedData).then((success) => {
            setIsLoading(false);
            dispatch(fetchLoadingSuccess(false))
            if (success && success.status && success.status.error === false) {
                setPatientId(success.id);
                setIsShowOTPModal(true);
                setShowGuestSessionConfirm(false);
            }
        }).catch((err) => {
            setIsLoading(false);
            dispatch(fetchLoadingSuccess(false))
            toast.error(err.msg ? err.msg : 'Something is wrong.');
        });
    }
    useEffect(() => {
        setDeptAppt(getValues('deptAppt'));
        if (apptRequiredASAP) {
            setValue('appointmentSlot', '');
            setValue('starttime', selectedSlot ? moment(selectedSlot.startdate + " " + selectedSlot.startTime, "YYYY-MM-DD LT") : moment());
            setValue('endtime', selectedSlot ? moment(selectedSlot.startdate + " " + selectedSlot.startTime, "YYYY-MM-DD LT").add(30, 'minutes') : moment().add(30, 'minutes'));
        }
        // setApptRequiredASAP(getValues('apptRequiredASAP'))
    }, [watch('deptAppt'), watch('apptRequiredASAP')]);

    useEffect(() => {
        console.log(asapAppt);
        if (asapAppt === "Show" || asapAppt === "Hide") {
            setValue('apptRequiredASAP', !apptRequiredASAP);
            setApptRequiredASAP(!apptRequiredASAP);
        }
    }, [asapAppt]);

    const handleOtpVerifySuccess = (data: any) => {
        console.log("handleOtpVerifySuccess", data);
        dispatch(fetchLoadingPending());
        signInWithCustomToken(data.jwtToken).then((response) => {
            const key1 = 'xprUser.patient-' + data.id;
            const key2 = 'xpr_user_id';
            const key3 = 'xprAuthUser';
            const key4 = 'xprUserName';
            const key5 = 'jwtToken';
            const key6 = 'xprIsGuarantor';
            var json: any = {};
            json[key1] = true;
            json[key2] = data.uniqueId;
            json[key3] = data.authParam;
            json[key4] = data.firstName + " " + data.lastName;
            json[key5] = data.jwtToken;
            json[key6] = data.isGuarantor;
            Cookies.remove('patient_cookie');
            Cookies.remove('patient_cookie', { domain: configs.COOKIE_DOMAIN });
            Cookies.set('patient_cookie', json);
            getPatientList(data.uniqueId).then((success: any) => {
                console.log("getPatientList", success);
                setIsLoading(true);
                dispatch(fetchLoadingPending())
                if (success && success.status && success.status.error === false) {
                    const dependant = success.dependant[0];
                    const guarantor = success.guarantor;
                    setGuarantorId(guarantor);
                    setDependant(dependant);
                    console.log(apptRequiredASAP)
                    if (!apptRequiredASAP) {
                        OnSubmitRequest({
                            startdate: guestDetails.date,
                            startTime: guestDetails.starttime,
                            starttime: guestDetails.starttime,
                            endtime: moment(moment(guestDetails.starttime).add("minutes", doctor?.potentialAppointmentTypes[0].duration)),
                            dependant: dependant,
                            guarantor: guarantor,

                        });
                    } else {
                        OnSubmitRequest({
                            startdate: selectedSlot ? selectedSlot.startdate : moment().format("YYYY-MM-DD"),
                            startTime: selectedSlot ? selectedSlot.startTime : moment().format("LT"),
                            starttime: selectedSlot ? selectedSlot.startTime : moment().format("LT"),
                            endtime: selectedSlot ? selectedSlot.endTime : moment().format("LT"),
                            dependant: dependant,
                            guarantor: guarantor
                        });
                    }
                }
            }).catch((err) => {
                setIsLoading(false);
                dispatch(fetchLoadingSuccess(false))
                console.log(err);
            })
        })
    }
    const addStatusToFirebase = (appointmentId: any, dependantId: any, patientId: any, successMessage: any) => {
        var db = firebase.firestore();
        let dateTimeObj: any = {};
        console.log(apptRequiredASAP, guestDetails, getValues('apptRequiredASAP'));
        if (!apptRequiredASAP) {
            dateTimeObj = {
                "date": moment(guestDetails.date, "YYYY-MM-DD").format('YYYY-MM-DD'),
                "time": moment(guestDetails.starttime).format('LT'),
                "startTime": moment(guestDetails.starttime).format('LT'),
                "endTime": moment(guestDetails.endtime).format('LT')
            }
        } else {
            dateTimeObj = {
                "date": selectedSlot ? selectedSlot.startdate : moment().format("YYYY-MM-DD"),
                "time": selectedSlot ? selectedSlot.startTime : moment().format("LT"),
                "startTime": selectedSlot ? selectedSlot.startTime : moment().format("LT"),
                "endTime": selectedSlot ? selectedSlot.endTime : moment().format("LT")
            }
        }
        console.log('dateTimeObj', dateTimeObj);
        const obj = {
            "appointmentId": Number(appointmentId),
            "senderId": Number(patientId),
            "sender": 'patient',
            "action": 'Changed status from CREATED to REQUESTED.',
            "actionType": 'STATUS',
            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
            "fromStatus": 'CREATED',
            "toStatus": 'REQUESTED',
            "sendToCId": doctor.clinic_uniqueId,
            "dependantId": Number(dependantId),
            "dateTime": {
                ...dateTimeObj
            },
        };
        db.collection(configs.CHAT_COLLECTION_NAME).doc().set(obj)
            .then(() => {
                console.log("Document successfully written!");
                const login: any = getCookie();
                if (login) {
                    setTimeout(() => {
                        setIsLoading(false);
                        dispatch(fetchLoadingSuccess(false))
                        toast.success(successMessage);
                        bookAppointmentSuccess();
                    }, 1000);
                } else {
                    toast.error('Something went wrong.');
                    console.log('else do logoout');
                    setIsLoading(false);
                    dispatch(fetchLoadingSuccess(false))
                }
            })
            .catch((error: any) => {
                toast.error('Something went wrong.');
                console.error("Error writing document: ", error);
                setIsLoading(false);
                dispatch(fetchLoadingSuccess(false))
            });
    }
    const bookAppointmentRequest = (data: any) => {
        console.log("dependant-bookAppointmentRequest", dependant)
        const clinic = {
            id: doctor.clinic_id,
            doctor: {
                id: doctor.id
            }
        };
        const request = {
            isNewPatient: true,
            isExistingClinic: false,
            isFromApp: true,
            patient: {
                id: data.guarantor,
            },
            clinic: clinic,
            appointmenttype: {
                id: doctor.potentialAppointmentTypes[0].id
            },
            dependant: {
                id: data.dependant.id,
                etime: guestDetails?.etime ? moment(guestDetails.etime).format("LT") : '',
                slot: guestDetails?.appointmentSlot?.map((item: any) => item.value).toString() ?? '',
            },
            request: {
                isNewPatient: true,
                additionalNote: "",
            },
        }
        appointmentRequest(request).then((success) => {
            if (success && success.status && success.status.error === false) {
                console.log('success', success);
                addStatusToFirebase(success.appopintment, success.dependant, success.patient, success.status.msg);
            } else if (success && success.status && success.status.error === true) {
                setIsLoading(false);
                dispatch(fetchLoadingSuccess(false))
                if (success.status.msg) {
                    toast.error(success.status.msg);
                } else {
                    toast.error('Something went wrong.');
                }
            }
        }).catch((err) => {
            setIsLoading(false);
            dispatch(fetchLoadingSuccess(false))
            console.log(err);
        })
    }
    const checkTimeSlotAvailability = (data: any) => {
        let request = {
            "clinicId": doctor.clinic_id,
            "doctorId": doctor.id,
            "start_date": moment(guestDetails.date).format('YYYY-MM-DD'),
            "start_time": moment(guestDetails.starttime).format('hh:mm A'),
            "end_time": moment(guestDetails.starttime).add("minutes", doctor?.potentialAppointmentTypes[0].duration).format('hh:mm A')
        }
        console.log("checkTimeSlotAvailability", dependant)
        bookappointmentSlotCheck(request).then((success) => {
            if (success && success.status && success.status.error === false) {
                console.log('success', success);
                if (success.avability !== "no") {
                    bookAppointmentRequest(data);
                } else {
                    toast.warn('This time slot is not available.');
                    setIsLoading(false);
                    dispatch(fetchLoadingSuccess(false))
                    return;
                }
            } else if (success && success.status && success.status.error === true) {
                setIsLoading(false);
                dispatch(fetchLoadingSuccess(false))
                if (success.status.msg) {
                    toast.error(success.status.msg);
                } else {
                    toast.error('Something went wrong.');
                }
            }
        }).catch((err) => {
            setIsLoading(false);
            dispatch(fetchLoadingSuccess(false))
            console.log(err);
        })
    }
    const OnSubmitRequest = (data: any) => {
        if (moment(data.startdate + " " + data.startTime, 'YYYY-MM-DD LT').isBefore(moment().format('YYYY-MM-DD LT'))) {
            toast.warn('Select future time.');
            return;
            dispatch(fetchLoadingSuccess(false))
        }
        if (firebase.auth().currentUser?.uid) {
            setIsLoading(true);
            dispatch(fetchLoadingPending())
            bookAppointmentRequest(data);
            /* let isBlocked = checkBlockSlot(data);
            if (isBlocked === undefined) {
                checkTimeSlotAvailability(data)
            } else {
                toast.warn('This time slot is not available.');
                setIsLoading(false);
                dispatch(fetchLoadingSuccess(false))
                return;
            } */
        } else {
            setIsLoading(false);
            dispatch(fetchLoadingSuccess(false))
            toast.error('Please signin again. after logout.')
            return;
        }
    }
    const checkBlockSlot = (data: any) => {
        let endTime: any = moment(data.endtime).format('LT');
        let startDate: any = moment(data.date).format('YYYY-MM-DD');
        let startTime: any = moment(data.starttime).format('LT');
        let isBlocked: any = undefined;
        isBlocked = blockSlotData.find((item: any) => {
            if (item.blockslot_type === 'full') {
                return doctor.id === item.doctorId && doctor.clinic_Id === item.clinicId && moment(startDate).isSame(moment(item.startdate));
            } else if (item.blockslot_type === 'partial') {
                return (doctor.id === item.doctorId &&
                    doctor.clinic_id === item.clinicId &&
                    (moment(startDate + " " + startTime, 'YYYY-MM-DD LT').isSame(moment(item.startdate + " " + item.starttime, 'YYYY-MM-DD LT')) ||
                        moment(startDate + " " + startTime, 'YYYY-MM-DD LT').isSame(moment(item.enddate + " " + item.endtime, 'YYYY-MM-DD LT')) ||
                        moment(item.enddate + " " + item.endtime, 'YYYY-MM-DD LT').isBetween(moment(startDate + " " + startTime, 'YYYY-MM-DD LT'),
                            moment(startDate + " " + endTime, 'YYYY-MM-DD LT')) ||
                        moment(item.startdate + " " + item.starttime, 'YYYY-MM-DD LT')
                            .isBetween(moment(startDate + " " + startTime, 'YYYY-MM-DD LT'),
                                moment(startDate + " " + endTime, 'YYYY-MM-DD LT')) ||
                        moment(startDate + " " + startTime, 'YYYY-MM-DD LT').isBetween(moment(item.startdate + " " + item.starttime, 'YYYY-MM-DD LT'),
                            moment(item.enddate + " " + item.endtime, 'YYYY-MM-DD LT'))
                    ))
            }
            return (doctor.id === item.doctorId &&
                doctor.clinic_id === item.clinicId &&
                (moment(startDate + " " + startTime, 'YYYY-MM-DD LT').isSame(moment(item.startdate + " " + item.starttime, 'YYYY-MM-DD LT')) ||
                    moment(startDate + " " + startTime, 'YYYY-MM-DD LT').isSame(moment(item.enddate + " " + item.endtime, 'YYYY-MM-DD LT')) ||
                    moment(item.enddate + " " + item.endtime, 'YYYY-MM-DD LT').isBetween(moment(startDate + " " + startTime, 'YYYY-MM-DD LT'),
                        moment(startDate + " " + endTime, 'YYYY-MM-DD LT')) ||
                    moment(item.startdate + " " + item.starttime, 'YYYY-MM-DD LT')
                        .isBetween(moment(startDate + " " + startTime, 'YYYY-MM-DD LT'),
                            moment(startDate + " " + endTime, 'YYYY-MM-DD LT')) ||
                    moment(startDate + " " + startTime, 'YYYY-MM-DD LT').isBetween(moment(item.startdate + " " + item.starttime, 'YYYY-MM-DD LT'),
                        moment(item.enddate + " " + item.endtime, 'YYYY-MM-DD LT'))
                ))
        });
        return isBlocked;
    }
    console.log("selectedSlot", guestDetails)

    const onBookClick = (values: any) => {
        const clinic = {
            id: doctor.clinic_id,
            doctor: {
                id: doctor.id
            }
        };
        const requestObj: any = {
            isNewPatient: true,
            isExistingClinic: false,
            isFromApp: true,
            clinic: clinic,
            appointmenttype: {
                id: doctor.potentialAppointmentTypes[0].id
            },
            dependant: {
                fname: guestDetails.firstName,
                lname: guestDetails.lastName,
                email: guestDetails.email,
                phone: guestDetails.phone,
                dob: moment(guestDetails.dob).format('YYYY-MM-DD'),
                gender: guestDetails.gender,
                etime: guestDetails?.etime ? moment(guestDetails.etime).format("LT") : '',
                slot: guestDetails?.appointmentSlot?.map((item: any) => item.value).toString() ?? '',
            },
            request: {
                isNewPatient: true,
                additionalNote: "",
            },
        };
        if (deptAppt) {
            Object.assign(requestObj, {
                guarantor: {
                    fname: guestDetails.gfirstName,
                    lname: guestDetails.glastName,
                    email: guestDetails.email,
                    phone: guestDetails.phone,
                    dob: moment(guestDetails.dob).format('YYYY-MM-DD'),
                    gender: guestDetails.gender,
                },
            })
        }
        if (!apptRequiredASAP) {
            Object.assign(requestObj, {
                dateTime: {
                    date: moment(guestDetails.date).format("YYYY-MM-DD"),
                    time: moment(guestDetails.starttime).format("LT"),
                    startTime: moment(guestDetails.starttime).format("LT"),
                    endTime: moment(moment(guestDetails.starttime).add("minutes", doctor?.potentialAppointmentTypes[0].duration)).format("LT"),
                }
            })
        } else {
            Object.assign(requestObj, {
                dateTime: {
                    date: selectedSlot ? selectedSlot.startdate : moment().format("YYYY-MM-DD"),
                    time: selectedSlot ? selectedSlot.startTime : moment().format("LT"),
                    startTime: selectedSlot ? selectedSlot.startTime : moment().format("LT"),
                    endTime: selectedSlot ? selectedSlot.endTime : moment().format("LT"),
                }
            })
        }
        console.log("request", requestObj);
        dispatch(fetchLoadingPending());
        bookAppointmentGuest(requestObj).then((success) => {
            if (success && success.status && success.status.error === false) {
                toast.success(success.status.msg);
                var db = firebase.firestore();
                const obj = {
                    "sender": 'utility',
                    "action": 'Appt requested from utility.',
                    "actionType": 'STATUS',
                    "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                    "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                    "fromStatus": 'CREATED',
                    "toStatus": 'GUEST',
                    "sendToCId": doctor.clinic_uniqueId,
                    "dateTime": requestObj.dateTime
                };
                db.collection(configs.CHAT_COLLECTION_NAME).doc().set(obj)
                    .then(() => {
                        console.log("added", obj);
                        dispatch(fetchLoadingSuccess(false))
                        bookAppointmentSuccess()
                    }).catch((err) => {
                        bookAppointmentSuccess()
                        dispatch(fetchLoadingSuccess(false))
                    });
            } else if (success && success.status && success.status.error === true) {
                setIsLoading(false);
                dispatch(fetchLoadingSuccess(false))
                if (success.status.msg) {
                    toast.error(success.status.msg);
                } else {
                    toast.error('Something went wrong.');
                }
            }
        }).catch((err) => {
            setIsLoading(false);
            dispatch(fetchLoadingSuccess(false))
            console.log(err);
        })
    }
    useEffect(() => {
        if (getValues('appointmentSlot')) {
            const value: any = getValues('appointmentSlot');
            if (value.find((item: any) => item.value === "morning")) {
                setValue('starttime', moment(moment().format("DD MMM YYY") + " 7:00 AM"))
                if (value.find((item: any) => item.value === "evening")) {
                    setTimeout(() => {
                        setValue('endtime', moment(moment().format("DD MMM YYY") + " 07:00 PM"))
                    }, 500);
                } else if (value.find((item: any) => item.value === "afternoon")) {
                    setTimeout(() => {
                        setValue('endtime', moment(moment().format("DD MMM YYY") + " 03:00 PM"))
                    }, 500);
                } else {
                    setTimeout(() => {
                        setValue('endtime', moment(moment().format("DD MMM YYY") + " 11:00 AM"))
                    }, 500);
                }
            } else if (value.find((item: any) => item.value === "afternoon")) {
                setValue('starttime', moment(moment().format("DD MMM YYY") + " 11:00 AM"))
                if (value.find((item: any) => item.value === "evening")) {
                    setTimeout(() => {
                        setValue('endtime', moment(moment().format("DD MMM YYY") + " 07:00 PM"))
                    }, 500);
                } else {
                    setTimeout(() => {
                        setValue('endtime', moment(moment().format("DD MMM YYY") + " 03:00 PM"))
                    }, 500);
                }
            } else if (value.find((item: any) => item.value === "evening")) {
                setValue('starttime', moment(moment().format("DD MMM YYY") + " 03:00 PM"))
                setTimeout(() => {
                    setValue('endtime', moment(moment().format("DD MMM YYY") + " 07:00 PM"))
                }, 500);
            } else {
                setValue('starttime', '')
                setTimeout(() => {
                    setValue('endtime', '')
                }, 500);
            }
            setTimeout(() => {
                setValue('appointmentSlot', value);
            }, 500);
        }
    }, [watch('appointmentSlot')]);
    return (
        <>
            <Modal className="master_popup public_popup regPopup" tabIndex={1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" show={showGuest} onHide={hideModal} >
                <Modal.Header closeButton>
                    <Modal.Title>Guest Session</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="d-flex align-items-center flex-wrap">
                            <div className="form-check mb-2 mr-3">
                                <input className="form-check-input" type="checkbox" name="deptAppt" id="deptAppt" ref={register()} />
                                <label className="form-check-label" htmlFor="selfguarantor">Is this appointment for dependent?</label>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-6">
                                <label className="label-form">First name</label>
                                <input type="text" id="firstName" name="firstName" className="form-control" placeholder="Enter first name" ref={register({ required: true })} />
                                {
                                    (errors && errors.firstName && errors.firstName.type === 'required') &&
                                    <div className="is-invalid">First name is required</div>
                                }
                            </div>
                            <div className="col-md-6">
                                <label className="label-form">Last name</label>
                                <input type="text" className="form-control" name="lastName" placeholder="Enter last name" ref={register({ required: true })} />
                                {
                                    (errors && errors.lastName && errors.lastName.type === 'required') &&
                                    <div className="is-invalid">Last name is required</div>
                                }
                            </div>
                        </div>
                        <div className={"form-row " + (deptAppt ? "" : "d-none")}>
                            <div className="col-md-6">
                                <label className="label-form">Guarantors first name</label>
                                <input type="text" id="firstName" name="gfirstName" className="form-control" placeholder="Enter guarantors first name" ref={register({
                                    required: deptAppt ? true : false
                                })} />
                                {
                                    (errors && errors.gfirstName && errors.gfirstName.type === 'required') &&
                                    <div className="is-invalid">Guarantors first name is required</div>
                                }
                            </div>
                            <div className="col-md-6">
                                <label className="label-form">Guarantors Last name</label>
                                <input type="text" className="form-control" name="glastName" placeholder="Enter guarantors last name" ref={register({
                                    required: deptAppt ? true : false
                                })} />
                                {
                                    (errors && errors.glastName && errors.glastName.type === 'required') &&
                                    <div className="is-invalid">Guarantors last name is required</div>
                                }
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-6">
                                <label className="label-form">Email address</label>
                                <input type="email" className="form-control" name="email" placeholder="Enter email" ref={register({
                                    required: true,
                                    pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                })} />
                                {
                                    (errors && errors.email && errors.email.type === 'required') &&
                                    <div className="is-invalid">Email is required</div>
                                }
                            </div>
                            <div className="col-md-6">
                                <label className="label-form">Mobile number</label>
                                <input type="text" className="form-control" name="phone" maxLength={10} placeholder="Enter mobile"
                                    ref={register({ required: true, minLength: 10, maxLength: 10 })} />
                                {
                                    (errors && errors.phone && errors.phone.type === 'required') &&
                                    <div className="is-invalid">Mobile number is required</div>
                                }
                                {
                                    (errors && errors.phone && errors.phone.type === 'minLength') &&
                                    <div className="is-invalid">Mobile number is invalid</div>
                                }
                                {
                                    (errors && errors.phone && errors.phone.type === 'maxLength') &&
                                    <div className="is-invalid">Mobile number is invalid</div>
                                }
                            </div>
                            <div className="col-md-6">
                                <label className="label-form">Gender</label>
                                <select className="form-control" name="gender" ref={register({ required: true })}>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </div>
                            <div className="col-md-6">
                                <label className="d-block form-label">Date of Birth</label>
                                <Controller
                                    name="dob"
                                    control={control}
                                    defaultValue={moment().subtract(1, 'day')}
                                    render={(props) => (
                                        <KeyboardDatePicker
                                            id="date-picker-dialog"
                                            format="YYYY/MM/DD"
                                            name="dob"
                                            value={props.value}
                                            maxDate={moment().subtract(1, 'day')}
                                            onChange={(date: any) => props.onChange(date)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    )}
                                ></Controller>
                            </div>
                        </div>
                        {
                            asapAppt !== "Hide" &&
                            <div className="d-flex align-items-center flex-wrap">
                                <div className="form-check my-2 mr-3">
                                    <input className="form-check-input" type="checkbox" name="apptRequiredASAP" checked={apptRequiredASAP} id="apptRequiredASAP" onChange={() => {
                                        setValue('apptRequiredASAP', !apptRequiredASAP);
                                        setApptRequiredASAP(!apptRequiredASAP);
                                    }} />
                                    <label className="form-check-label" htmlFor="selfguarantor">I want my appointment as soon as possible</label>
                                </div>
                            </div>
                        }
                        {
                            !apptRequiredASAP &&
                            <>
                                <div className="form-row mb-3 ">
                                    <div className="col mt-3 mt-md-0">
                                        <label className="d-block fw-bold">Appointment slots</label>
                                        <Controller
                                            name="appointmentSlot"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true, message: 'Appointment time slot is required'
                                                }
                                            }}
                                            render={(props) => (
                                                <Select
                                                    options={[
                                                        { value: 'morning', label: 'Morning' },
                                                        { value: 'afternoon', label: 'Afternoon' },
                                                        { value: 'evening', label: 'Evening' },
                                                    ]}
                                                    name="appointmentSlot"
                                                    className="w-100" isMulti
                                                    value={props.value}
                                                    onChange={props.onChange}
                                                ></Select>
                                            )}
                                        ></Controller>
                                        {
                                            (errors && errors.appointmentSlot && errors.appointmentSlot.type === 'required') &&
                                            <div className="is-invalid">{errors.appointmentSlot.message}</div>
                                        }
                                    </div>
                                </div>
                                <div className={"form-row mb-3 "}>
                                    <div className="col-md-4 col-12 mt-3 mt-md-0">
                                        <label className="d-block fw-bold">Date</label>
                                        <Controller
                                            name="date"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true, message: 'Appointment date is required'
                                                }
                                            }}
                                            render={(props) => (
                                                <KeyboardDatePicker
                                                    id="date-picker-dialog"
                                                    format="YYYY/MM/DD"
                                                    name="date"
                                                    value={props.value}
                                                    minDate={moment()}
                                                    onChange={(date: any) => props.onChange(date)}
                                                    // disabled={asapAppt === "Hide"}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            )}
                                        ></Controller>
                                    </div>
                                    {
                                        !hideTime &&
                                        <>
                                            <div className={"col-md-4 col-12 mt-3 mt-md-0"}>
                                                <label className="d-block fw-bold">Start time</label>
                                                <Controller
                                                    name="starttime"
                                                    control={control}
                                                    rules={{
                                                        required: {
                                                            value: true, message: 'Start time is required'
                                                        }
                                                    }}
                                                    render={(props) => (
                                                        <KeyboardTimePicker
                                                            id="date-picker-dialog"
                                                            name="starttime"
                                                            value={props.value}
                                                            onChange={(date: any) => props.onChange(date)}
                                                            disabled={asapAppt === "Hide"}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                        />
                                                    )}
                                                ></Controller>
                                            </div>
                                            <div className={"col-md-4 col-12 mt-3 mt-md-0"}>
                                                <label className="d-block fw-bold">End time</label>
                                                <Controller
                                                    name="endtime"
                                                    control={control}
                                                    render={(props) => (
                                                        <KeyboardTimePicker
                                                            id="date-picker-dialog"
                                                            name="endtime"
                                                            value={props.value}
                                                            onChange={(date: any) => props.onChange(date)}
                                                            disabled={asapAppt === "Hide"}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                        />
                                                    )}
                                                ></Controller>
                                            </div>
                                        </>
                                    }
                                </div>
                            </>
                        }
                        <div className="d-flex align-items-center">
                            <label>Note: You will be contacted by clinic admin to finalize this appointment shortly.</label>
                            <div className="flax-grow-1">
                                <button onClick={handleSubmit(onRegisterClick)} className="btn btn-dark">
                                    <span className="aft_load right"></span>Submit</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {
                showGuestSessionConfirm &&
                <GuestSessionConfirm
                    showGuestSessionConfirm={showGuestSessionConfirm}
                    hideModal={handleHideGuestSessionConfirm}
                    onSignupClick={onSignupClick}
                    onBookClick={onBookClick}
                >
                </GuestSessionConfirm>
            }
            {
                isShowOTPModal && guestDetails && patientId !== "" &&
                <OTPModal hideModal={() => {
                    setIsShowOTPModal(false);
                    hideModal();
                }}
                    sentEmail={guestDetails.email}
                    sentPhone={guestDetails.phone}
                    patientId={patientId}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    codeVerifySuccessLogin={handleOtpVerifySuccess}
                ></OTPModal>
            }
        </>
    )
}

const GuestSessionConfirm = ({
    hideModal,
    showGuestSessionConfirm,
    onSignupClick,
    onBookClick
}: any) => {
    const { register, setError, handleSubmit, setValue, getValues, errors, trigger, control, watch } = useForm({
        defaultValues: {
            password: '',
            cpassword: '',
        }
    });
    const [isSignup, setIsSignup] = useState(false);
    const handleSignupClick = (values: any) => {
        onSignupClick(values);
    }
    const handleBookClick = (values: any) => {
        onBookClick(values)
    }
    return (
        <Modal className="master_popup public_popup regPopup" tabIndex={1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" show={showGuestSessionConfirm} onHide={hideModal} >
            <Modal.Header closeButton>
                <Modal.Title>Your appointment is being processed!!!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="d-flex align-items-center flex-wrap">
                        <label>Save time next time by Signing up today.</label><br />
                        <label>We will keep you informed about your appointment through mobile SMS and/or Email notifications</label>
                    </div>
                    <div className="d-flex align-items-center flex-wrap">
                        <div className="form-check my-2 mr-3">
                            <input className="form-check-input" type="checkbox" name="wantToSignUp" id="wantToSignUp" checked={isSignup} onChange={() => {
                                setIsSignup(!isSignup);
                            }} />
                            <label className="form-check-label" htmlFor="wantToSignUp">I am ready to Sign up?</label>
                        </div>
                    </div>
                    {
                        isSignup &&
                        <div className="form-row">
                            <div className="col-md-6">
                                <label className="label-form">Password</label>
                                <input type="password" className="form-control" name="password" placeholder="Enter password"
                                    ref={register({
                                        required: true,
                                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
                                    })} />
                                {
                                    (errors && errors.password && errors.password.type === 'required') &&
                                    <div className="is-invalid">Password is required</div>
                                }
                                {
                                    (errors && errors.password && errors.password.type === 'pattern') &&
                                    <div className="is-invalid">Password must contain 8 characters with at least 1 upper case, 1 lower case, 1 special and 1 numeric character.</div>
                                }
                            </div>
                            <div className="col-md-6">
                                <label className="label-form">Re-type Password</label>
                                <input type="password" className="form-control" name="cpassword" placeholder="Enter re-type password"
                                    ref={register({
                                        required: true,
                                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
                                    })} />
                                {
                                    (errors && errors.cpassword && errors.cpassword.type === 'required') &&
                                    <div className="is-invalid">Confirm Password is required</div>
                                }
                                {
                                    (errors && errors.cpassword && errors.cpassword.type === 'pattern') &&
                                    <div className="is-invalid">Password must contain 8 characters with at least 1 upper case, 1 lower case, 1 special and 1 numeric character.</div>
                                }
                            </div>
                        </div>
                    }
                    <div className="d-flex justify-content-end">
                        {
                            isSignup &&
                            <label>Note: For security reasons you may be asked to enter an OTP next time when you Sign in.</label>
                        }
                        {
                            isSignup &&
                            <div className="flex-shrink-0">
                                <button onClick={handleSubmit(handleSignupClick)} type="submit" className="btn btn-dark">
                                    <span className="aft_load right"></span>Sign up</button>
                            </div>
                        }
                        {
                            !isSignup &&
                            <button onClick={handleSubmit(handleBookClick)} type="submit" className="btn btn-dark">
                                <span className="aft_load right"></span>Proceed without Sign up</button>
                        }
                    </div>
                    <input type="hidden" name="globalError" ref={register} />
                </div>
            </Modal.Body>
        </Modal>
    )
}