import { FilterState, FilterType, FILTERS } from "./types";
export const initialState: FilterState = {
    filters: {
        location: '',
        speciality: '',
        date: null,
        gender:[],
        language:[],
        clinic:'',
        doctor:'',
        distance:'',
        filterItems:{
            location: '',
            speciality: '',
            date: null,
            gender:[],
            language:[],
            clinic:'',
            doctor:'',
            distance:'',
            insuranceProvider:'',
            insurancePlan:'',
            paymentMethod:[],
            socialAssistance:'',
            userLocation:'',
            selectedClinic:'',
            selectedProvider: '',
            clinicId: '',
            isClearFilter: false, // when click on clearFilter button date navigation start with today's date
            patientUniqueId: '',
            page: 1,
            totalCount:0
        }
    }
}

export const filterReducer = (state = initialState, action: FilterType) => {
    switch (action.type) {
        case FILTERS.ADD_LOCATION:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    location: action.payload
                },
            }
        case FILTERS.ADD_SPECIALITY:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    speciality: action.payload
                },
            }
        case FILTERS.ADD_DATE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    date: action.payload
                },
            }
        case FILTERS.ADD_GENDER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    gender: action.payload
                },
            }
        case FILTERS.ADD_LANGUAGE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    language: action.payload.language,
                    languageObj:action.payload.languageObj
                },
            }
        case FILTERS.ADD_CLINIC:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    clinic: action.payload
                },
            }
        case FILTERS.ADD_DOCTOR:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    doctor: action.payload
                },
            }
        case FILTERS.ADD_DISTANCE:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    distance: action.payload
                },
            }
            case FILTERS.ALL_FILTER_DATA:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    filterItems: action.payload
                },
            }
        default:
        return state;
    }

}