import React, { useState } from 'react';
import { Animated } from 'react-animated-css';
import { Modal, Spinner } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { useForm } from 'react-hook-form';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LABELS, TITLES_LABELS } from '../../../constants';
import { checkForgotpasswordAccount, forgotPassword } from '../../../services/auth';
import { emailValidation } from '../../../../../utils/validators';
import Logo from "../../../../../assets/images/logo-black.svg";

import { useEffect } from 'react';
export const ForgotPassword = ({
    onSuccess,
    hideModal,
    backToRegister,
    backToSignin
}: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const { register, setError, handleSubmit, setValue, getValues, errors, trigger, watch } = useForm();
    const [askFor, setAskFor] = useState("");
    const history = useHistory();
    const location = useLocation();
    const [isSingleAccount, setIsSingleAccount]: any = useState('');
    const [emailOrPhoneState, setEmailOrPhoneState]: any = useState('');

    const onClickForgotPassword = (data: any) => {
        console.log('call', isSingleAccount, data);
        let requestData: any = {
            modelType: 'patient'
        };
        if (isNaN(getValues('emailorphone'))) {
            requestData = {
                ...requestData,
                email: getValues('emailorphone'), mobile: getValues('phone') ? getValues('phone') : '', isSingleAccount: isSingleAccount
            }
        } else {
            requestData = {
                ...requestData,
                mobile: getValues('emailorphone'), email: getValues('email') ? getValues('email') : '', isSingleAccount: isSingleAccount
            };
        }
        let obj = {};
        if (isNaN(getValues('emailorphone'))) {
            obj = {
                ...obj,
                email: getValues('emailorphone'), phone: getValues('phone'), isSingleAccount: isSingleAccount
            }
        } else {
            obj = {
                ...obj,
                phone: getValues('emailorphone'), email: getValues('email'), isSingleAccount: isSingleAccount
            };
        }
        if (isSingleAccount !== "") {
            setIsLoading(true);
            forgotPassword(requestData).then((success) => {
                setIsLoading(false);
                if (success && success.status && success.status.error === false) {
                    toast.success(success.status.msg);
                    console.log(obj);
                    onSuccess(success.status.patientotpId, obj);
                }
            }).catch((err: any) => {
                console.log(err);
                setIsLoading(false);
                setError('emailorphone', { type: 'custom', message: err.msg ? err.msg : 'Something went wrong' });
            });
        }
    }

    const callForgotNew = (isSingleAccount: boolean) => {
        console.log("emailOrPhoneState", emailOrPhoneState)
        let requestData: any = {
            modelType: 'patient'
        };
        if (isNaN(emailOrPhoneState)) {
            requestData = {
                ...requestData,
                email: emailOrPhoneState, mobile: '', isSingleAccount: isSingleAccount
            }
        } else {
            requestData = {
                ...requestData,
                mobile: emailOrPhoneState, email: '', isSingleAccount: isSingleAccount
            };
        }
        let obj = {};
        if (isNaN(emailOrPhoneState)) {
            obj = {
                ...obj,
                email: emailOrPhoneState, isSingleAccount: isSingleAccount
            }
        } else {
            obj = {
                ...obj,
                phone: emailOrPhoneState, isSingleAccount: isSingleAccount
            };
        }
        setIsLoading(true);
        forgotPassword(requestData).then((success) => {
            setIsLoading(false);
            if (success && success.status && success.status.error === false) {
                toast.success(success.status.msg);
                onSuccess(success.status.patientotpId, obj);
            }
        }).catch((err: any) => {
            console.log(err);
            setIsLoading(false);
            setError('emailorphone', { type: 'custom', message: err.msg ? err.msg : 'Something went wrong' });
        });
    }

    const emailOrPhoneBlur = async (e: any) => {
        try {
            const isValid = await trigger('emailorphone');
            if (isValid) {
                if (isNaN(getValues('emailorphone'))) {
                    if (!emailValidation(getValues('emailorphone'))) {
                        setError('emailorphone', { type: 'custom', message: 'Invalid email' });
                        return;
                    }
                } else {
                    if (getValues('emailorphone').length > 10 || getValues('emailorphone').length < 10) {
                        setError('emailorphone', { type: 'custom', message: 'Invalid phone' });
                        return;
                    }
                }
                setIsLoading(true);
                checkForgotpasswordAccount({ login: getValues('emailorphone'), modelType: 'patient' }).then((success) => {
                    setIsLoading(false);
                    if (success && success.status && success.status.error === false) {
                        setAskFor(success.askFor);
                        setIsSingleAccount(success.isSingleAccount);
                        if (success.isSingleAccount) {
                            callForgotNew(success.isSingleAccount);
                        }
                    }
                }).catch((err) => {
                    setIsLoading(false);
                    console.log(err);
                    setError('emailorphone', { type: 'custom', message: err.msg });
                });
            }
        } catch (err) {
            console.log(err);
        }
    }
    useEffect(() => {
        setIsSingleAccount("");
        setEmailOrPhoneState(getValues('emailorphone'));
    }, [watch('emailorphone')])
    return (
        <Modal className="master_popup public_popup loginPopup" tabIndex={1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" show={true} onHide={hideModal} >
            <Modal.Header closeButton>
                <Modal.Title>Forgot password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="">
                    <div className="mb-3">
                        {LABELS.FORGOT_PASSWORD_DESC}
                    </div>
                    <div className="mb-3">
                        <label className="form-label fw-medium">{LABELS.EMAILORPHONE}</label>
                        <input type="text" disabled={isLoading} className="form-control" name="emailorphone" ref={register({ required: true })} onBlur={emailOrPhoneBlur} />
                        {
                            (errors && errors.emailorphone && errors.emailorphone.type === 'required') &&
                            <div className="text-danger error">Email or phone is required.</div>
                        }
                        {
                            (errors && errors.emailorphone && errors.emailorphone.type === 'custom') &&
                            <div className="text-danger error">{errors.emailorphone.message}</div>
                        }
                    </div>
                    {
                        askFor === "email" &&
                        <div className="mb-3">
                            <p>
                                We have detected multiple accounts with this mobile number, please enter email associated with this account to forgot password.
                                In case if you do not have email associated with this account please directly hit button.
                            </p>
                            <label className="form-label fw-medium">{LABELS.EMAIL}</label>
                            <input type="text" disabled={isLoading} className="form-control" name="email" ref={register({
                                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            })} />
                            {
                                (errors && errors.email && errors.email.type === 'pattern') &&
                                <div className="text-danger error">Email is invalid</div>
                            }
                        </div>
                    }
                    {
                        askFor === "phone" &&
                        <div className="mb-3">
                            <p>
                                We have detected multiple accounts with this email, please enter mobile number associated with this account to forgot password.
                                In case if you do not have mobile number associated with this account please directly hit button.
                            </p>
                            <label className="form-label fw-medium">{LABELS.PHONE}</label>
                            <input type="text" disabled={isLoading} className="form-control" name="phone" ref={register({
                                maxLength: 10, minLength: 10
                            })} />
                            {
                                (errors && errors.phone && errors.phone.type === 'maxLength') &&
                                <div className="text-danger error">Mobile number is invalid</div>
                            }
                            {
                                (errors && errors.phone && errors.phone.type === 'minLength') &&
                                <div className="text-danger error">Mobile number is invalid</div>
                            }
                        </div>
                    }
                    <div className="mt-3 bottom-links">
                        <button className="btn btn-dark w-100" onClick={handleSubmit(onClickForgotPassword)}>
                            <span>
                                <>
                                    {
                                        isLoading === true &&
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="ms-2"
                                        />
                                    }
                                </>
                            </span>
                            {LABELS.BUTTON.SEND_RESET_LINK}
                        </button>
                        <div className="mt-2 bottom-links">
                            <a className="d-block text-right" href="javascript:void(0)" onClick={(e) => {
                                backToSignin();
                            }}>Sign in</a>
                            <div className="mt-4 text-center">Don't have an account? <a href="javascript:void(0)" onClick={(e) => {
                                backToRegister();
                            }}>{TITLES_LABELS.LINKS.REGISTER}</a>
                            </div>
                        </div>
                    </div>
                </div>


            </Modal.Body>
        </Modal>
    )
}