import * as firebase from 'firebase/app';
import "firebase/firestore";
var firebaseConfig = {
    apiKey: "AIzaSyCJTjnL3te-Wpitm0hx97HxyWuBsHa-yFE",
    authDomain: "xcare-df106.firebaseapp.com",
    databaseURL: "https://xcare-df106.firebaseio.com",
    projectId: "xcare-df106",
    storageBucket: "xcare-df106.appspot.com",
    messagingSenderId: "404899970927",
    appId: "1:404899970927:web:b5d1a785735800cc38729a"
};
firebase.initializeApp(firebaseConfig);