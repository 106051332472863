import React from 'react';
export function showAddress(address: any) {
    return (
        <>
            {address.route !== "" ? address.route + ', ' : ''}
            {
                (address.sublocality && address.sublocality !== "") ?
                    <><br/>{address.sublocality}, </>
                    : (address.neighborhood && address.neighborhood !== "") ?
                        <><br/> {address.neighborhood}, </>
                        : (address.locality && address.locality !== "") ?
                            <><br/>{address.locality + ', '}</> : null
            }

            {
                address.address_level_1_short !== "" ? address.address_level_1_short + ', ' : ''
            }
            {
                address.postal !== "" ? address.postal : ''
            }
        </>
    )
}