import moment from "moment";
import { SearchFilterState, SearchFilterType, SEARCHFILTER } from "./types";
export const initialState: SearchFilterState = {
    searchFilterData: {
        oldSpeciality: '',
        newSpeciality: '',
        location: '',
        date: moment(),
        howToPay: '',
        gender: '',
        distance: '',
        languages: '',
        clinicId: '',
        providerId: '',
        patientUniqueId: '',
        page: '',
        clinicPage: '',
        results: '',
    }
}

export const searchFilterReducer = (state = initialState, action: SearchFilterType) => {
    switch (action.type) {
        case SEARCHFILTER.SEARCH_FILTER_DATA:
            return {
                ...state,
                searchFilterData: action.payload,
            }
        default:
            return state;
    }
}