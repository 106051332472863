import {SLOT, SlotState, SlotType} from './types';

export const initialState: SlotState = {
    slot:[]
}

export const slotReducer = (state = initialState, action: SlotType) => {
    switch (action.type) {
        case SLOT.ADD_SLOT_DATA:
            return {
                ...state,
                slot:action.payload
            }
        default:
            return state;
    }
}