import * as jQuery from 'jquery';
jQuery(document).ready(function () {
    // testimonial bg
    var bgWidth = jQuery(".testimonials").innerWidth();
    jQuery(".testimonials .bg-img").css("border-right-width", bgWidth + "px");
    var bgHeight = jQuery(".testimonials").innerHeight();
    jQuery(".testimonials .bg-img").css("border-bottom-width", bgHeight + "px");
    

});

// responsive menu
jQuery('a.target-burger').click(function (e) {
    jQuery('nav.mobile-nav, a.target-burger, body').toggleClass('toggled');
    e.preventDefault();
    jQuery('.mobile-nav .menu li:has(ul)').append('<span class="dsarrow"></span>');

    jQuery('.mobile-nav .sub-menu').slideUp();
    jQuery(".mobile-nav .menu li:has(ul)").find(".dsarrow").click(function () {
        // if(false == jQuery(this).parent().children(".mobile-nav .menu li ul.sub-menu").is(':visible')) {
        //     // jQuery('.mobile-nav .menu li ul.sub-menu').slideUp();
        // }
        jQuery(this).parent().children(".mobile-nav .menu li ul.sub-menu").slideToggle();
    });
});

/*Sticky Menu start*/
jQuery(window).scroll(function () {

    if (jQuery(this).scrollTop() > 150) {
        jQuery('.master-header').addClass("sticky");
    }
    else {
        jQuery('.master-header').removeClass("sticky");
    }
});
/*Sticky Menu end*/


