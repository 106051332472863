import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { isLogin } from '../../../../utils/global';
import { Register } from '../../pages/Auth/Register';
import { Login } from '../Auth/Login';
import { PatientList } from '../PatientList/PatientList';
import { Book } from '../Book/Book';
import { GuestSession } from '../Auth/guestSession/GuestSession';
import configs from '../../../../configs/apiConfigs';

export const AppointmentBook = (props: any) => {
    const [isAlreadyLogin, setIsAlreadyLogin] = useState(false);
    const [isShowPatientList, setIsShowPatientList] = useState(false);
    const [isShowBookAppointment, setIsShowBookAppointment] = useState(false);
    const [guarantorId, setGuarantorId] = useState('');
    const [dependant, setDependant] = useState('');
    const [doctor, setDoctor] = useState('');
    const [selectedSlot, setSelectedSlot] = useState('');
    const [blockSlotData, setBlockSlotData] = useState('');
    const [showGuestSession, setShowGuestSession] = useState(false);
    useEffect(() => {
        console.log('props', props);
        setSelectedSlot(props.selectedSlot);
        setDoctor(props.selectedDoctor);
        setBlockSlotData(props.blockSlotData);
        console.log("patient_cookie", isLogin("patient_cookie"))
        if (!showGuestSession) {
            if (!isLogin('patient_cookie')) {
                setIsAlreadyLogin(true);
            } else {
                setIsShowPatientList(true);
            }
        }
    }, [props]);
    const hideModal = () => {
        setIsAlreadyLogin(false);
        setIsShowPatientList(false);
        setIsShowBookAppointment(false);
        props.hideModal();
    }
    const handleContinueAsGuest = () => {
        setShowGuestSession(true);
        setIsAlreadyLogin(false);
        setIsShowPatientList(false);
        setIsShowBookAppointment(false);
    }
    return (
        <>
            {
                (isAlreadyLogin) &&
                <Login
                    from="AppointmentBook"
                    isVisible={isAlreadyLogin}
                    hideModal={hideModal}
                    continueAsGuest={handleContinueAsGuest}
                    onLoginSuccess={() => {
                        setIsShowPatientList(true);
                        setTimeout(() => {
                            setIsAlreadyLogin(false);
                        }, 500);
                    }}
                />
            }
            {
                isShowPatientList &&
                <PatientList
                    isVisible={isShowPatientList}
                    hideModal={hideModal}
                    onClickNext={(guarantorId: any, dependant: any) => {
                        setIsShowPatientList(false);
                        setGuarantorId(guarantorId);
                        setDependant(dependant);
                        setIsShowBookAppointment(true);
                    }}
                />
            }
            {
                (isShowBookAppointment) &&
                <Book
                    isVisible={isShowBookAppointment}
                    hideModal={hideModal}
                    selectedSlot={selectedSlot}
                    doctor={doctor}
                    blockSlotData={blockSlotData}
                    guarantorId={guarantorId}
                    dependant={dependant}
                    bookSlotSuccess={() => {
                        hideModal();
                        props.bookAppointmentSuccess();
                    }}
                    asapAppt={props.asapAppt}
                    hideTime={props.hideTime}
                />
            }
            {
                showGuestSession &&
                <GuestSession
                    asapAppt={props.asapAppt}
                    selectedDate={props.selectedDate}
                    selectedSlot={selectedSlot}
                    doctor={doctor}
                    blockSlotData={blockSlotData}
                    showGuestSession={showGuestSession}
                    bookAppointmentSuccess={() => {
                        hideModal();
                        // Cookies.remove('patient_cookie');
                        // Cookies.remove('patient_cookie', { domain: configs.COOKIE_DOMAIN });
                        props.bookAppointmentSuccess();
                    }}
                    hideModal={() => {
                        // Cookies.remove('patient_cookie');
                        // Cookies.remove('patient_cookie', { domain: configs.COOKIE_DOMAIN });
                        setShowGuestSession(false);
                        hideModal();
                    }}
                    hideTime={props.hideTime}
                ></GuestSession>
            }
        </>
    )
}