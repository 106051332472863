import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Modal, Spinner } from 'react-bootstrap';
import { getPatientList } from '../../services/AppointmentBook';
import { AddMember } from '../AddMember/AddMember';

export const PatientList = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [patientList, setPatientList] = useState([] as any);
    const [isGuarantor, setIsGuarantor] = useState(false);
    const [isAddMember, setIsAddMember] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState('' as any);
    const [isNoRecord, setIsNoRecord] = useState(false);
    const [patientUniqueId, setPatientUniqueId] = useState('');
    const [selectedGuarantor, setSelectedGuarantor] = useState('');
    useEffect(() => {
        getPatientListApi();
    }, []);
    const getPatientListApi = () => {
        let id: any = '';
        if (Cookies.get('patient_cookie')) {
            let data: any;
            data = Cookies.getJSON('patient_cookie');
            if (data.xpr_user_id !== "") {
                id = data.xpr_user_id;
                setPatientUniqueId(data.xpr_user_id);
                if (data && data.xprIsGuarantor && data.xprIsGuarantor === true) {
                    setIsGuarantor(true);
                }
            }
        }
        if (id) {
            setIsLoading(true);
            getPatientList(id).then((success) => {
                setIsLoading(false);
                if (success && success.status && success.status.error === false) {
                    setPatientList(success);
                    setSelectedGuarantor(success.guarantor);
                    if (success.dependant && success.dependant.length > 0) {
                        setIsNoRecord(true);
                    }
                }
            }).catch((err) => {
                setIsLoading(false);
                console.log(err);
            })
        }
    }
    const handleSelectPatient = (item: any) => {
        setSelectedPatient(item);
    }
    const hideModal = () => {
        setIsAddMember(false);
    }
    const handleNext = () => {
        props.onClickNext(selectedGuarantor, selectedPatient);
    }
    return (
        <>
            <Modal className="master_popup public_popup book_popup_nw listOfPatients" tabIndex={1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" show={props.isVisible} onHide={props.hideModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Family members</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        (isGuarantor) &&
                        <div className="d-flex justify-content-end mb-2">
                            <button className="border-btn btn addBtn pr-0" onClick={() => { setIsAddMember(true) }} >Add Family Member</button>
                        </div>
                    }
                    {
                        (patientList && patientList.dependant && patientList.dependant.length > 0) ?
                            <ul className="list-group">
                                {
                                    patientList.dependant.map((item: any, index: number) => {
                                        let check: any = false;
                                        if (item.id === selectedPatient.id) {
                                            check = true;
                                        }
                                        return (
                                            <li key={index + Math.random() + 'patient'} onClick={() => { handleSelectPatient(item) }} className={check ? "list-group-item active" : "list-group-item"}>
                                                <div className="patient-name-txt">{item.firstName + " " + item.lastName}</div>
                                                {/* <span className="text-muted small">{state.guarantorName}</span> */}
                                            </li>
                                        )
                                    })
                                }
                                <button disabled={selectedPatient === ""} className="btn btn-dark mt-3 ml-auto" onClick={() => { handleNext() }}>Next</button>
                            </ul>
                            :
                            isNoRecord ? <h3>No Record </h3> :
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                    }
                </Modal.Body>
            </Modal>
            {
                (isAddMember) &&
                <AddMember
                    isVisible={isAddMember}
                    hideModal={hideModal}
                    patientUniqueId={patientUniqueId}
                    addDependantSuccess={() => {
                        setIsAddMember(false);
                        getPatientListApi();
                    }}
                />
            }
        </>
    )
}