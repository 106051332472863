import React from "react";
import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import configs from "../../configs/apiConfigs";
import { FailureWidget } from "../containers/FailureWidget";
import { ProcessionWidget } from "../containers/ProcessingWidget";
import { SuccessWidget } from "../containers/SuccessWidget";
import { verifyApiKey } from "../services/utility";

const Widget = () => {
    /* Always use hooks below */
    const match: any = useRouteMatch();

    /* Always declare state below */
    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [logoUrl, setLogoUrl] = useState('');

    useEffect(() => {
        if (match.params.apiKey && match.params.clinicId) {
            verifyApiKey(match.params.apiKey, match.params.clinicId).then((success: any) => {
                console.log(success.response.data.valid)
                if (success.response.data.valid) {
                    setIsValid(true);
                    setIsLoading(false);
                    setLogoUrl(configs.IMAGE_URL + 'clinic/' + success.response.data.logo.name + '-200x200.' + success.response.data.logo.type);
                } else {
                    setIsValid(false);
                    setIsLoading(false);
                }
            }).catch((err) => {
                console.log(err);
            })
        } else {
            setIsValid(false);
            setIsLoading(false);
        }
    }, [match]);

    if (isValid && !isLoading) {
        return (
            <SuccessWidget logo={logoUrl}></SuccessWidget>
        );
    }
    // if (!isValid && !isLoading) {
    //     return (
    //         <FailureWidget></FailureWidget>
    //     );
    // }
    // if (!isValid && isLoading) {
    //     return (
    //         <ProcessionWidget></ProcessionWidget>
    //     );
    // }
    return null;
}

export default Widget;