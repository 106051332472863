export enum FILTERS {
    ADD_LOCATION = 'ADD_LOCATION',
    ADD_SPECIALITY ='ADD_SPECIALITY',
    ADD_DATE='ADD_DATE',
    ADD_GENDER='ADD_GENDER',
    ADD_LANGUAGE='ADD_LANGUAGE',
    ADD_CLINIC='ADD_CLINIC',
    ADD_DOCTOR='ADD_DOCTOR',
    ADD_DISTANCE='ADD_DISTANCE',
    ALL_FILTER_DATA="ALL_FILTER_DATA"
}
export interface FilterState {
    filters: {
        location: any;
        speciality: any;
        date: any;
        gender:any;
        language:any;
        clinic:any;
        doctor:any;
        distance:any;
        filterItems:AddFilterItems;
    }
}

export interface AddLocation {
    location: any;
}
export interface AddSpeciality {
    speciality: any;
}
export interface AddDate {
    date: any;
}
export interface AddGender {
    gender: any;
}
export interface AddClinic {
    clinic: any;
}
export interface AddDoctor {
    doctor: any;
}
export interface AddLanguage {
    languageObj: any;
    language:any;
}
export interface AddDistance {
    distance: any;
}
export interface AddFilterItems {
    location: any;
    speciality: any;
    date: any;
    gender:any;
    language:any;
    clinic:any;
    doctor:any;
    distance:any;
    insuranceProvider:any,
    insurancePlan:any,
    paymentMethod:any,
    socialAssistance:any
    userLocation:any;
    selectedClinic:any;
    selectedProvider: any;
    clinicId: any;
    isClearFilter: any;
    patientUniqueId: any;
    page: any;
    totalCount: any;
}
export interface AddLocationAction {
    type: FILTERS.ADD_LOCATION,
    payload: AddLocation;
}
export interface AddSpecialityAction {
    type: FILTERS.ADD_SPECIALITY,
    payload: AddSpeciality;
}
export interface AddDateAction {
    type: FILTERS.ADD_DATE,
    payload: AddDate;
}
export interface AddGenderAction {
    type: FILTERS.ADD_GENDER,
    payload: AddGender;
}
export interface AddLanguageAction {
    type: FILTERS.ADD_LANGUAGE,
    payload: AddLanguage;
}
export interface AddClinicAction {
    type: FILTERS.ADD_CLINIC,
    payload: AddClinic;
}
export interface AddDoctorAction {
    type: FILTERS.ADD_DOCTOR,
    payload: AddDoctor;
}
export interface AddDistanceAction {
    type: FILTERS.ADD_DISTANCE,
    payload: AddDistance;
}
export interface AddFilterItemsAction {
    type: FILTERS.ALL_FILTER_DATA,
    payload: AddFilterItems;
}

export type FilterType = AddLocationAction | AddSpecialityAction | AddDateAction | AddGenderAction |
            AddLanguageAction | AddClinicAction | AddDoctorAction | AddDistanceAction | AddFilterItemsAction;
