export enum LOADING {
    LOAD_PENDING = 'LOAD_PENDING_LOADING',
    LOAD_ERROR = 'LOAD_FAILED_LOADING',
    LOAD_SUCCESS = 'LOAD_SUCCESS_LOADING'
};
export interface LoadingState {
    pending: boolean;
}

export interface FetchLoadingPendingAction {
    type: LOADING.LOAD_PENDING;
}

export interface FetchLoadingErrorAction {
    type: LOADING.LOAD_ERROR;
}
export interface FetchLoadingSuccessAction {
    type: LOADING.LOAD_SUCCESS;
    pending: boolean
}

export type LoadingActionTypes = FetchLoadingErrorAction | FetchLoadingPendingAction | FetchLoadingSuccessAction;