import { LOADING, FetchLoadingErrorAction, FetchLoadingPendingAction, FetchLoadingSuccessAction } from './types';
/* Action Creators */
export const fetchLoadingPending = (): FetchLoadingPendingAction => {
    return {
        type: LOADING.LOAD_PENDING,
    };
};

export const fetchLoadingSuccess = (pending: boolean): FetchLoadingSuccessAction => {
    return {
        type: LOADING.LOAD_SUCCESS,
        pending: pending
    }
}

export const fetchLoginError = (err: any): FetchLoadingErrorAction => {
    return {
        type: LOADING.LOAD_ERROR
    }
}