import axios from 'axios';
import apiConfigs from '../configs/apiConfigs';
import {attachHeader} from '../utils/apiHandler'
export const getSpeciality = () => {
    return axios.get(apiConfigs.API_URL + 'specialities/', {
        headers: {
            'xprApp': 'front'
        }
    }).then(response => ({ response }), error => ({ error }));
}

export const getInsuranceProviderNew = () => {
    return axios.get(apiConfigs.API_URL + 'master/insurance/', {
        headers: {
            'xprApp': 'front'
        }
    }).then(response => ({ response }), error => ({ error }));
}
export const getInsurancePlan = (request:any) => {
    return axios.post(apiConfigs.API_URL + 'insurancesplan/',request ,{
        headers: {
            'xprApp': 'front'
        }
    }).then(response => ({ response }), error => ({ error }));
}

export const getSocialAssistance = () => {
    return axios.get(apiConfigs.API_URL + 'master/social-assistance/', {
        headers: {
            'xprApp': 'front'
        }
    }).then(response => ({ response }), error => ({ error }));
}

export const clinicAndProviderList = (request: any) => {
    if (request.location.patientUniqueId) {
        return axios.post(apiConfigs.API_URL + 'resultsNew/', request, {
            headers: attachHeader(true),
            withCredentials: true,
        }).then(response => ({ response }), error => ({ error }));
    } else {
        return axios.post(apiConfigs.API_URL + 'resultsNew/', request, {
            headers: {
                'xprApp': 'front',
                "content-type": 'application/json'
            }
        }).then(response => ({ response }), error => ({ error }));
    }
}
export const getHygienes = () => {
    return axios.get(apiConfigs.API_URL + 'hygienes/', {
        headers: {
            'xprApp': 'front'
        }
    }).then(response => ({ response }), error => ({ error }));
}

export const getLanguageList = () => {
    return axios.get(apiConfigs.API_URL + 'languages/', {
        headers: {
            'xprApp': 'front'
        }
    }).then(response => ({ response }), error => ({ error }));
}
export const appointmentSlot = (request:any) => {
    return axios.post(apiConfigs.API_URL + 'appointmentSlot/',request ,{
        headers: {
            'xprApp': 'front'
        }
    }).then(response => ({ response }), error => ({ error }));
}

export const patientList = (request:any) => {
    return axios.get(apiConfigs.API_URL + 'patient/'+request+'/patientlist/',{
        headers: attachHeader(true),
        withCredentials: true,
    }).then(response => ({ response }), error => ({ error }));
}

export const addDependant = (uniqueId:any,request:any) => {
    return axios.post(apiConfigs.API_URL + 'patient/'+uniqueId+'/dependant/add/',request ,{
        headers: attachHeader(true),
        withCredentials: true,
    }).then(response => ({ response }), error => ({ error }));
}

export const bookappointmentSlotCheck = (request:any) => {
    return axios.post(apiConfigs.API_URL + 'bookappointmentSlotCheck/',request ,{
        headers: {
            'xprApp': 'front'
        }
    }).then(response => ({ response }), error => ({ error }));
}

export const existingClinicProvider = (request:any) => {
    return axios.post(apiConfigs.API_URL + 'patientappointmentsearch/',request ,{
        headers: {
            'xprApp': 'front'
        }
    }).then(response => ({ response }), error => ({ error }));
}

export const patientblocksearch = (request:any) => {
    return axios.post(apiConfigs.API_URL + 'patientblocksearch/',request ,{
        headers: {
            'xprApp': 'front'
        }
    }).then(response => ({ response }), error => ({ error }));
}

export const getInsuranceProvider = () => {
    return axios.get(apiConfigs.API_URL + 'insurances/', {
        headers: {
            'xprApp': 'front'
        }
    }).then(response => ({ response }), error => ({ error }));
}