import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import List from "../../components/LandingPageSearchNew/pages/List/List";
import { IAppState } from "../../store";
import { searchFilter } from "../../store/v2/searchFilter/actions";
import { ProviderList } from "../components/ProviderList";

export const SuccessWidget = ({ logo }: any) => {
    const searchFilterData = useSelector((state: IAppState) => state.searchFilter.searchFilterData, shallowEqual);
    const match: any = useRouteMatch();
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (match.params.clinicId) {
            dispatch(searchFilter({
                ...searchFilterData,
                clinicId: match.params.clinicId
            }));
            setTimeout(() => {
                setOpen(true)
            }, 1000);
        }
        /* setTimeout(() => {
            setOpen(true);
        }, 2000); */
    }, [])
    const handleOpen = () => {
        setOpen(!open);
    };
    return (
        <>
            {
                open &&
                <div className="success-popup">
                    {/* <List></List> */}
                    <ProviderList></ProviderList>
                </div>
            }
        </>
    )
};