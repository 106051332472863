export enum SLOT {
    ADD_SLOT_DATA='ADD_SLOT_DATA'
}

export interface SlotState {
    slot:any;
}

export interface Slot{
    slot:any;
}

export interface SlotAction {
    type: SLOT.ADD_SLOT_DATA,
    payload: Slot;
}

export type SlotType = SlotAction;
