export const allowAlphabetsAndSpace = (text: string) => {
    const regEx = /^[a-zA-Z ]*$/
    return regEx.test(text);
}
export const emailValidation = (text: string) => {
    const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEx.test(text);
}
export const urlValidator = (url: string) => {
    /* Allows only with http or https */
    // const regEx = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    const regEx = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g;
    return regEx.test(url);
}
export const checkIfEmptyOrNull = (text: string) => {
    text = text.trim();
    if (text === undefined || text === null || text === '') {
        return true;
    }
    return false;
}
export const postalCodeValidation = (text: string) => {
    text = text.trim();
    const regEx = /^[a-zA-Z]{1}\d{1}[a-zA-Z]{1} ?\d{1}[a-zA-Z]{1}\d{1}$/;
    return regEx.test(text);
}
export const allowDigit = (text: string) => {
    const regEx = /^[0-9]*$/
    return regEx.test(text);
}

export const allowOnlyAlpha = (text: string) => {
    text = text.trim();
    const regEx = /^[a-zA-Z\s]+$/;
    return regEx.test(text);
}
export const passwordValidator = (password: string) => {
    const regEx = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/;
    return regEx.test(password);
}
export const phoneValidator = (phone: string) => {
    const regEx = /^(\([0 - 9]{ 3 } \) | [0 - 9]{ 3 } -)[0 - 9]{ 3 } -[0 - 9]{ 4 }$/;
    return regEx.test(phone);
}
export const takeOnlyDigitAndPlus = (maskedText: string) => {
    return maskedText.replace(/[^0-9+]/g, "");
}
export const allowOnlyDigit = (text: string) => {
    text = text.trim();
    const regEx = /^[0-9]+$/;
    return regEx.test(text);
}