import { formwizardReducer } from './formwizard/reducers';
import { combineReducers, createStore, Store, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import CreateSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas';
import * as storage from 'redux-storage';
import createEngine from 'redux-storage-engine-localstorage';
import { landingPageReducer } from './landingPageSearch/reducers';
import { LANDING_PAGE } from '../store/landingPageSearch/types';
import { loadingReducer } from './loadingIndicator/reducers';
import { FORM_WIZARD } from './formwizard/types';
import { filterReducer } from './filters/reducers';
import { FILTERS } from '../store/filters/types';
import { clinicProviderListReducer } from './clinicAndProviderList/reducers';
import { CLINIC_PROVIDER_LIST } from './clinicAndProviderList/types';
import { SLOT } from './slot/types';
import { slotReducer } from './slot/reducers';
import { searchFilterReducer } from './v2/searchFilter/reducers';
import { SEARCHFILTER } from '../store/v2/searchFilter/types';
const appReducer = combineReducers({
    formwizard: formwizardReducer,
    landingPage: landingPageReducer,
    loading: loadingReducer,
    filters: filterReducer,
    clinicProviderList: clinicProviderListReducer,
    slot: slotReducer,
    searchFilter: searchFilterReducer
});
const rootReducer = (state: any, action: any) => {
    return appReducer(state, action);
}
const reducer = storage.reducer(rootReducer);
const engine = createEngine('front_key_store');
const storageMiddleware = storage.createMiddleware(engine, [], [
    /* FORM_WIZARD.ADD_LOCATION,
    FORM_WIZARD.ADD_ATTITUDE,
    FORM_WIZARD.ADD_REASAON,
    FORM_WIZARD.ADD_GOAL,
    FORM_WIZARD.REMOVE_GOAL,
    FORM_WIZARD.ADD_CRITERIA,
    FORM_WIZARD.REMOVE_CRITERIA,
    FORM_WIZARD.ADD_LAST_VISITED,
    FORM_WIZARD.ADD_DENTAL_ANXIETY,
    FORM_WIZARD.ADD_AVAILABILITY,
    FORM_WIZARD.REMOVE_AVAILABILITY,
    FORM_WIZARD.ADD_HOW_SOON,
    FORM_WIZARD.ADD_INSURANCE,
    FORM_WIZARD.ADD_INSURANCE_DETAIL,
    FORM_WIZARD.ADD_GENDER,
    FORM_WIZARD.ADD_SOCIAL_ASSISTANCE,
    FORM_WIZARD.ADD_READY_TO_BOOK,
    FORM_WIZARD.ADD_INFO,
    FORM_WIZARD.ADD_DENTIST_SERVICE,
    FORM_WIZARD.ADD_DENTIST_URGENT,
    FORM_WIZARD.REMOVE_DENTIST_SERVICE,
    FORM_WIZARD.ADD_NOT_READY_REASON,
    FORM_WIZARD.SET_TO_INITIAL,
    FORM_WIZARD.ADD_PERCENTAGE,
    LANDING_PAGE.ADD_INSURANCE_PLAN,
    LANDING_PAGE.ADD_DATE,
    LANDING_PAGE.ADD_ISINSURANCE,
    LANDING_PAGE.ADD_ISURANCE_PROVIDER,
    LANDING_PAGE.ADD_LOCATION,
    LANDING_PAGE.ADD_SPECIALITY,
    LANDING_PAGE.ADD_ISINSURANCE,
    LANDING_PAGE.ADD_PAYMENT_METHOD,
    LANDING_PAGE.ADD_SOCIAL_ASSISTANCE,
    LANDING_PAGE.FILTER_DATA,
    LANDING_PAGE.ADD_PRIMARY_INSURANCE,
    LANDING_PAGE.ADD_SECONDARY_INSURANCE,
    LANDING_PAGE.ADD_IS_IFRAME,
    FILTERS.ADD_LOCATION,
    FILTERS.ADD_SPECIALITY,
    FILTERS.ADD_DATE,
    FILTERS.ADD_GENDER,
    FILTERS.ADD_LANGUAGE,
    FILTERS.ADD_CLINIC,
    FILTERS.ADD_DOCTOR,
    FILTERS.ADD_DISTANCE,
    FILTERS.ALL_FILTER_DATA,
    CLINIC_PROVIDER_LIST.ADD_CLINIC_PROVIDER,
    SLOT.ADD_SLOT_DATA,
    SEARCHFILTER.SEARCH_FILTER_DATA */

]);
const loggerMiddleware = createLogger();
export type IAppState = ReturnType<typeof rootReducer>;
export default function configureStore(): Store<any, any> {
    const sagaMiddleware = CreateSagaMiddleware();
    const store = createStore(reducer, applyMiddleware(sagaMiddleware, /* storageMiddleware, */ loggerMiddleware));
    sagaMiddleware.run(rootSaga);
    const load = storage.createLoader(engine);
    load(store);
    return store;
}