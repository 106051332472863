import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import PinInput from 'react-pin-input';
import { toast } from 'react-toastify';
import configs from '../../../../configs/apiConfigs';
import { verifyCode } from '../../services/auth';
export const OTPModal = ({ hideModal, sentEmail, sentPhone, isLoading, setIsLoading, patientId, codeVerifySuccessLogin }: any) => {
    const [verificationCode, setVerificationCode] = useState('');
    const [verificationCodeEmail, setVerificationCodeEmail] = useState('');
    const [verificationCodeFalse, setVerificationCodeFalse] = useState('');
    const verifyCodeFunc = () => {
        if (patientId) {
            setIsLoading(true);
            const obj = {};
            if (verificationCode === '' && verificationCodeEmail === '') {
                setVerificationCodeFalse('Please enter code for atleat one.');
                return;
            }
            if (sentPhone) {
                Object.assign(obj, { code: verificationCode });
            }
            if (sentEmail) {
                Object.assign(obj, { ecode: verificationCodeEmail });
            }
            verifyCode(obj, patientId).then((success) => {
                setIsLoading(false);
                if (success && success.status && success.status.error === false) {
                    codeVerifySuccessLogin(success);
                    // hideModal();
                    return;
                }
                setVerificationCodeFalse('Something went wrong');
            }).catch((err) => {
                setIsLoading(false);
                setVerificationCodeFalse(err.msg);
                console.log(err);
            })
        }
    }
    const sendVerificationCodeEmail = () => {
        if (patientId) {
            setIsLoading(true);
            axios.post(configs.API_URL + 'register1/' + patientId + '/resend/email/', {
                email: sentEmail
            }).then((success) => {
                setIsLoading(false);
                if (success.status === 200) {
                    if (success.data.status.error === false) {
                        toast.success(success.data.status.msg);
                        setVerificationCodeFalse(success.data.status.msg);
                    } else {
                        toast.error(success.data.status.msg);
                    }
                }
            }, (error) => {
                setIsLoading(false);
                toast.error('Something went wrong');
            });
        }
    }
    const sendVerificationCode = () => {
        if (patientId) {
            setIsLoading(true);
            axios.post(configs.API_URL + 'register1/' + patientId + '/resend/phone/', {
                phone: sentPhone
            }).then((success) => {
                setIsLoading(false);
                if (success.status === 200) {
                    if (success.data.status.error === false) {
                        toast.success(success.data.status.msg);
                        setVerificationCodeFalse(success.data.status.msg);
                    } else {
                        toast.error(success.data.status.msg);
                    }
                }
            }, (error) => {
                setIsLoading(false);
                toast.error('Something went wrong');
            });
        }
    }
    return (
        <Modal centered className="master_popup public_popup loginPopup" show={true} onHide={() => {
            hideModal();
        }}>
            <Modal.Header closeButton>
                <Modal.Title>OTP</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="popup-form text-center">
                    {/* <div className="popup-title text-center">
                    Verify your number
                            </div> */}
                    <div className="popup-form text-center">
                        {
                            (sentPhone) && (sentEmail) &&
                            <p>We have sent a verification code, please check your mobile and email.</p>
                        }
                        {
                            (sentPhone) && (!sentEmail) &&
                            <p>We have sent a verification code, please check your mobile.</p>
                        }
                        {
                            (sentEmail) && (!sentPhone) &&
                            <p>We have sent a verification code, please check your email.</p>
                        }
                        {
                            sentPhone &&
                            <>
                                <div className="form-txt">Enter an OTP here for mobile number verification</div>
                                <div className="form-group">
                                    <PinInput
                                        length={4}
                                        initialValue={""}
                                        onChange={(value, index) => {
                                            setVerificationCode(value);
                                        }}
                                        type="numeric"
                                        style={{ padding: '10px' }}
                                        inputStyle={{}}
                                        inputFocusStyle={{ borderColor: 'blue' }}
                                        onComplete={(value, index) => {
                                            setVerificationCode(value);
                                        }}
                                    />
                                    {
                                        <button className="btn resend-btn" onClick={(e) => { sendVerificationCode() }}>
                                            Resend Code
                                            {
                                                isLoading &&
                                                <span className="aft_load right">
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            }
                                        </button>
                                    }
                                </div>
                            </>
                        }
                        {
                            sentEmail &&
                            <>
                                <div className="form-txt">Enter an OTP here for email verification</div>
                                <div className="form-group">
                                    <PinInput
                                        length={4}
                                        initialValue={""}
                                        onChange={(value, index) => {
                                            setVerificationCodeEmail(value);
                                        }}
                                        type="numeric"
                                        style={{ padding: '10px' }}
                                        inputStyle={{}}
                                        inputFocusStyle={{ borderColor: 'blue' }}
                                        onComplete={(value, index) => {
                                            setVerificationCodeEmail(value);
                                        }}
                                    />
                                    {
                                        <button className="btn resend-btn" onClick={(e) => { sendVerificationCodeEmail() }}>
                                            Resend Code
                                            {
                                                isLoading &&
                                                <span className="aft_load right">
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            }
                                        </button>
                                    }
                                </div>
                            </>
                        }
                        {
                            verificationCodeFalse !== '' &&
                            <div className="text-danger error">{verificationCodeFalse}</div>
                        }

                        <button className="btn btn-custom send-btn my-3"
                            onClick={(e) => { verifyCodeFunc() }}
                            disabled={((sentEmail && verificationCodeEmail.length !== 4) || (sentPhone && verificationCode.length !== 4)) || isLoading}>
                            <img src={require('../../assets/images/ic_arrow_upward_24px.svg')} />
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}