export enum LANDING_PAGE {
    ADD_LOCATION = 'ADD_LOCATION',
    ADD_SPECIALITY ='ADD_SPECIALITY',
    ADD_DATE='ADD_DATE',
    ADD_ISINSURANCE='ADD_ISINSURANCE',
    ADD_ISURANCE_PROVIDER='ADD_INSURANCE_PROVIDER',
    ADD_INSURANCE_PLAN='ADD_INSURANCE_PLAN',
    ADD_PAYMENT_METHOD='ADD_PAYMENT_METHOD',
    ADD_SOCIAL_ASSISTANCE='ADD_SOCIAL_ASSISTANCE',
    FILTER_DATA='FILTER_DATA',
    ADD_PRIMARY_INSURANCE="ADD_PRIMARY_INSURANCE",
    ADD_SECONDARY_INSURANCE="ADD_SECONDARY_INSURANCE",
    ADD_IS_IFRAME='ADD_IS_IFRAME'
}
export interface LandingPage {
    location: any;
    speciality: any;
    date: any;
    isInsurance:boolean,
    insuranceProvider:any,
    insurancePlan:any,
    paymentMethod:any,
    socialAssistance:any
    filterData:AddfilterDataRequest;
    primaryInsurance:any;
    secondaryInsurance:any;
    isIframe:boolean;
}
/* Types */
export interface AddLocation {
    location: any;
}
export interface AddSpeciality {
    speciality: any;
}
export interface AddDate {
    date: any;
}
export interface AddIsInsurance {
    isInsurance: any;
}
export interface AddInsuranceProvider {
    insuranceProvider: any;
}
export interface AddInsurancePlan {
    insurancePlan: any;
}
export interface AddPaymentMethod {
    paymentMethod: any;
}
export interface AddSocialAssistance {
    socialAssistance: any;
}
export interface AddPrimaryInsurance {
    // primaryInsurance:any;
}
export interface AddSecondaryInsurance {
    // secondaryInsurance:any;
}
export interface AddIsIframe {
    isIframe:any;
}
export interface AddfilterDataRequest {
        location: any;
        userLocation:any;
        speciality: any;
        date: any;
        isInsurance:boolean,
        insuranceProvider:any,
        insurancePlan:any,
        paymentMethod:any,
        socialAssistance:any
}
/* State */
export interface LandingPageState {
    landingPage: LandingPage;
}
/* Action creators types */
export interface AddLocationAction {
    type: LANDING_PAGE.ADD_LOCATION,
    payload: AddLocation;
}
export interface AddSpecialityAction {
    type: LANDING_PAGE.ADD_SPECIALITY,
    payload: AddSpeciality;
}
export interface AddDateAction {
    type: LANDING_PAGE.ADD_DATE,
    payload: AddDate;
}
export interface AddIsInsuranceAction {
    type: LANDING_PAGE.ADD_ISINSURANCE,
    payload: AddIsInsurance;
}
export interface AddInsuranceProviderAction {
    type: LANDING_PAGE.ADD_ISURANCE_PROVIDER,
    payload: AddInsuranceProvider;
}
export interface AddInsurancePlanAction {
    type: LANDING_PAGE.ADD_INSURANCE_PLAN,
    payload: AddInsurancePlan;
}
export interface AddPaymentMethodAction {
    type: LANDING_PAGE.ADD_PAYMENT_METHOD,
    payload: AddPaymentMethod;
}
export interface AddSocialAssistanceAction {
    type: LANDING_PAGE.ADD_SOCIAL_ASSISTANCE,
    payload: AddSocialAssistance;
}
export interface AddfilterDataAction {
    type: LANDING_PAGE.FILTER_DATA,
    payload: AddfilterDataRequest;
}
export interface AddPrimaryInsuranceAction {
    type: LANDING_PAGE.ADD_PRIMARY_INSURANCE,
    payload: AddPrimaryInsurance;
}
export interface AddSecondaryInsuranceAction {
    type: LANDING_PAGE.ADD_SECONDARY_INSURANCE,
    payload: AddSecondaryInsurance;
}
export interface AddIsIframeAction {
    type: LANDING_PAGE.ADD_IS_IFRAME,
    payload: AddIsIframe;
}

export type LandingPageType = AddLocationAction | AddSpecialityAction | AddDateAction | AddIsInsuranceAction |
            AddInsurancePlanAction | AddInsuranceProviderAction | AddPaymentMethodAction |
            AddSocialAssistanceAction | AddfilterDataAction | AddPrimaryInsuranceAction | AddSecondaryInsuranceAction |
            AddIsIframeAction;