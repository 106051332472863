import React, { useState } from 'react';
import { Animated } from 'react-animated-css';
import { Modal, Spinner } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LABELS, TITLES_LABELS } from '../../../constants';
import { verifyOtpForgotpassword } from '../../../services/auth';
import { useEffect } from 'react';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import Logo from "../../../../../assets/images/logo-black.svg";
export const ForgotPasswordOtp = ({
    patientOtpId, hideModal, onSuccess, frgtObj
}: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const { register, setError, handleSubmit, setValue, getValues, errors, trigger } = useForm();
    const history = useHistory();
    const location: any = useLocation();
    const match: any = useRouteMatch();
    const onOtpVerifyClick = (data: any) => {
        if (patientOtpId) {
            setIsLoading(true);
            const obj = {
                patientotpId: patientOtpId,
                modelType: "verifyOtp"
            };
            if (frgtObj && frgtObj.email) {
                Object.assign(obj, {
                    otpeverify: getValues('eotp')
                });
            }
            if (frgtObj && frgtObj.phone) {
                Object.assign(obj, {
                    otppverify: getValues('potp')
                });
            }
            verifyOtpForgotpassword(obj).then((success) => {
                setIsLoading(false);
                if (success && success.status && success.status.error === false) {
                    onSuccess(success.status.patientuniqueId);
                } else {
                    toast.error(success.status.msg);
                }
            }).catch((err) => {
                setIsLoading(false);
                toast.error(err.msg);
            });
        } else {
            toast.error("Invalid request");
        }
    }
    return (
        <ErrorBoundary>
            <Modal className="master_popup public_popup loginPopup" tabIndex={1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" show={true} onHide={hideModal} >
                <Modal.Header closeButton>
                    <Modal.Title>OTP verification</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <div className={"mb-3 " + ((frgtObj && frgtObj.email) ? '' : 'd-none')}>
                            <label className="form-label fw-medium">Please enter OTP received on email to confirm reset password.</label>
                            <input type="text" disabled={isLoading} className="form-control" name="eotp" placeholder="1234" maxLength={4} ref={register({
                                required: (frgtObj && frgtObj.email) ? true : false,
                                minLength: 4, maxLength: 4,
                                pattern: /^[0-9]+$/
                            })} />
                            {
                                (errors && errors.eotp && errors.eotp.type === 'required') &&
                                <div className="text-danger error">OTP is required.</div>
                            }
                            {
                                (errors && errors.eotp && errors.eotp.type === 'pattern') &&
                                <div className="text-danger error">Only number allowed.</div>
                            }
                            {
                                (errors && errors.eotp && errors.eotp.type === 'minLength') &&
                                <div className="text-danger error">Min length is 4.</div>
                            }
                            {
                                (errors && errors.eotp && errors.eotp.type === 'maxLength') &&
                                <div className="text-danger error">Max length is 4.</div>
                            }
                            {
                                (errors && errors.eotp && errors.eotp.type === 'custom') &&
                                <div className="text-danger error">{errors.eotp.message}</div>
                            }
                        </div>
                        <div className={"mb-3 " + ((frgtObj && frgtObj.phone) ? '' : 'd-none')}>
                            <label className="form-label fw-medium">Please enter OTP received on mobile# to confirm reset password.</label>
                            <input type="text" disabled={isLoading} className="form-control" name="potp" placeholder="1234" maxLength={4} ref={register({
                                required: (frgtObj && frgtObj.phone) ? true : false,
                                minLength: 4, maxLength: 4,
                                pattern: /^[0-9]+$/
                            })} />
                            {
                                (errors && errors.potp && errors.potp.type === 'required') &&
                                <div className="text-danger error">OTP is required.</div>
                            }
                            {
                                (errors && errors.potp && errors.potp.type === 'pattern') &&
                                <div className="text-danger error">Only number allowed.</div>
                            }
                            {
                                (errors && errors.potp && errors.potp.type === 'minLength') &&
                                <div className="text-danger error">Min length is 4.</div>
                            }
                            {
                                (errors && errors.potp && errors.potp.type === 'maxLength') &&
                                <div className="text-danger error">Max length is 4.</div>
                            }
                            {
                                (errors && errors.potp && errors.potp.type === 'custom') &&
                                <div className="text-danger error">{errors.potp.message}</div>
                            }
                        </div>
                        <button type="button" className="btn btn-dark w-100" onClick={handleSubmit(onOtpVerifyClick)}>
                            <span className="aft_load right">
                                <>
                                    {
                                        isLoading === true &&
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    }
                                </>
                            </span>
                            Verify
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </ErrorBoundary>
    )
}