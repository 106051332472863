import { FormWizardState, FormWizardType, FORM_WIZARD } from "./types";

export const initialState: FormWizardState = {
    formwizard: {
        location: '',
        attitude: '',
        reason: '',
        goals: [],
        criterias: [],
        lastVisited: '',
        dentalAnxiety: '',
        availabilities: [],
        howSoon: '',
        insurance: '',
        insuranceDetail: '',
        gender: '',
        socialAssistance: '',
        readyToBook: '',
        email: '', phone: '',
        dentistServices: [],
        dentistUrgent: '',
        notreadyreason: ''
    },
    percent: 0
};

export const formwizardReducer = (state = initialState, action: FormWizardType) => {
    const NewState = { ...state };
    const defaultState = initialState;
    switch (action.type) {
        case FORM_WIZARD.ADD_LOCATION:
            return {
                ...state,
                formwizard: {
                    ...state.formwizard,
                    location: action.payload
                },
            }
        case FORM_WIZARD.ADD_ATTITUDE:
            return {
                ...state,
                formwizard: {
                    ...state.formwizard,
                    attitude: action.payload
                },
            }
        case FORM_WIZARD.ADD_REASAON:
            return {
                ...state,
                formwizard: {
                    ...state.formwizard,
                    reason: action.payload
                },
            }
        case FORM_WIZARD.ADD_GOAL:
            return {
                ...state,
                formwizard: {
                    ...state.formwizard,
                    goals: [...state.formwizard.goals, action.payload]
                },
            }
        case FORM_WIZARD.REMOVE_GOAL:
            return {
                ...state,
                formwizard: {
                    ...state.formwizard,
                    goals: [...state.formwizard.goals.filter((goal) => {
                        //@ts-ignore
                        return goal.value !== action.payload.value
                    })]
                }
            }
        case FORM_WIZARD.ADD_CRITERIA:
            return {
                ...state,
                formwizard: {
                    ...state.formwizard,
                    criterias: [...state.formwizard.criterias, action.payload]
                },
            }
        case FORM_WIZARD.REMOVE_CRITERIA:
            return {
                ...state,
                formwizard: {
                    ...state.formwizard,
                    criterias: [...state.formwizard.criterias.filter((criteria) => {
                        //@ts-ignore
                        return criteria.value !== action.payload.value
                    })]
                }
            }
        case FORM_WIZARD.ADD_LAST_VISITED:
            return {
                ...state,
                formwizard: {
                    ...state.formwizard,
                    lastVisited: action.payload
                },
            }
        case FORM_WIZARD.ADD_DENTAL_ANXIETY:
            return {
                ...state,
                formwizard: {
                    ...state.formwizard,
                    dentalAnxiety: action.payload
                },
            }
        case FORM_WIZARD.ADD_AVAILABILITY:
            return {
                ...state,
                formwizard: {
                    ...state.formwizard,
                    availabilities: [...state.formwizard.availabilities, action.payload]
                },
            }
        case FORM_WIZARD.REMOVE_AVAILABILITY:
            return {
                ...state,
                formwizard: {
                    ...state.formwizard,
                    availabilities: [...state.formwizard.availabilities.filter((availability) => {
                        //@ts-ignore
                        return availability.value !== action.payload.value
                    })]
                }
            }
        case FORM_WIZARD.ADD_HOW_SOON:
            return {
                ...state,
                formwizard: {
                    ...state.formwizard,
                    howSoon: action.payload
                },
            }
        case FORM_WIZARD.ADD_INSURANCE:
            return {
                ...state,
                formwizard: {
                    ...state.formwizard,
                    insurance: action.payload
                },
            }
        case FORM_WIZARD.ADD_INSURANCE_DETAIL:
            return {
                ...state,
                formwizard: {
                    ...state.formwizard,
                    insuranceDetail: action.payload
                },
            }
        case FORM_WIZARD.ADD_GENDER:
            return {
                ...state,
                formwizard: {
                    ...state.formwizard,
                    gender: action.payload
                },
            }
        case FORM_WIZARD.ADD_SOCIAL_ASSISTANCE:
            return {
                ...state,
                formwizard: {
                    ...state.formwizard,
                    socialAssistance: action.payload
                },
            }
        case FORM_WIZARD.ADD_READY_TO_BOOK:
            return {
                ...state,
                formwizard: {
                    ...state.formwizard,
                    readyToBook: action.payload
                },
            }
        case FORM_WIZARD.ADD_INFO:
            return {
                ...state,
                formwizard: {
                    ...state.formwizard,
                    email: action.payload.payload.email,
                    phone: action.payload.payload.phone
                },
           }
        case FORM_WIZARD.ADD_DENTIST_SERVICE:
            return {
                ...state,
                formwizard: {
                    ...state.formwizard,
                    dentistServices: [...state.formwizard.dentistServices, action.payload]
                },
            }
        case FORM_WIZARD.REMOVE_DENTIST_SERVICE:
            return {
                ...state,
                formwizard: {
                    ...state.formwizard,
                    dentistServices: [...state.formwizard.dentistServices.filter((dentistService) => {
                        //@ts-ignore
                        return dentistService.id !== action.payload.id
                    })]
                }
            }
        case FORM_WIZARD.ADD_DENTIST_URGENT:
            return {
                ...state,
                formwizard: {
                    ...state.formwizard,
                    dentistUrgent: action.payload
                }
            }
        case FORM_WIZARD.ADD_NOT_READY_REASON:
            return {
                ...state,
                formwizard: {
                    ...state.formwizard,
                    notreadyreason: action.payload
                },
            }
        case FORM_WIZARD.SET_TO_INITIAL:
            try {
                localStorage.removeItem('front_key_store');
            } catch (err) {
                console.log(err);
            }
            return initialState;
        case FORM_WIZARD.ADD_PERCENTAGE:
            return {
                ...state,
                percent: action.percent
            };
        default:
            return NewState;
    }
};