export enum CLINIC_PROVIDER_LIST {
    ADD_CLINIC_PROVIDER='ADD_CLINIC_PROVIDER'
}

export interface ClinicProviderState {
    clinicProviderList:any;
}

export interface AddClinicProviderList{
    clinicList:any;
    providerList: any;
    providerTotalCount: any;
    clinicTotalCount: any;
    primaryInsurance: any;
}

export interface AddClinicProviderListAction {
    type: CLINIC_PROVIDER_LIST.ADD_CLINIC_PROVIDER,
    payload: AddClinicProviderList;
}

export type ClinicProviderType = AddClinicProviderListAction;
