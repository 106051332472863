import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../../assets/css/style.scss';
import { LoadingState } from '../../store/loadingIndicator/types';
import { IAppState } from '../../store';
import { connect } from 'react-redux';
import LoadingIndicator from '../../components/LandingPageSearchNew/pages/LoadingIndicator/LoadingIndicator';
import { isLogin } from '../../utils/global';
import { signInWithCustomToken } from '../../utils/firebaseUtils';
import Cookies from 'js-cookie';
import Widget from '../../v2/pages/Widget';

toast.configure({
    autoClose: 2000,
    draggable: true,
});
interface Props {
    match: any;
    loadingState: LoadingState;
    history: any;
}
interface State {
    isLoading: boolean;
    isLogin: any;
}
class HomeContainer extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: false, isLogin: '',
        }
    }

    componentDidMount() {
        let iFrame: any = false;
        if (this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.iFrame) {
            iFrame = true;
        }
        if (this.props.history && this.props.history.location && this.props.history.location.search) {
            iFrame = new URLSearchParams(this.props.history.location.search).get('iframe') === "yes" ? true : false;
        }
        if (!isLogin('patient_cookie') && iFrame === true) {
            this.setState({ isLogin: '' });
        } else if (iFrame === true) {
            let isLogin: any = Cookies.getJSON('patient_cookie');
            this.setState({ isLogin: isLogin });
            try {
                signInWithCustomToken(isLogin.jwtToken).then(async (signInSuccess) => {
                    console.log('firebase Login success');
                });
            } catch (error) {
                console.log('error Firebase Login', error);
            }
        }
    }
    shouldComponentUpdate(nextProps: Props, nextState: any) {
        if (nextProps.loadingState.pending !== this.props.loadingState.pending) {
            console.log('Indicator')
            if (nextProps.loadingState.pending === true) {
                console.log('Indicator: true');
                this.setState({ isLoading: true });
            } else {
                console.log('Indicator: false');
                this.setState({ isLoading: false });
            }
        }
        return true;
    }
    render() {
        return (
            <>
                {
                    this.state.isLoading &&
                    <>
                        <LoadingIndicator></LoadingIndicator>
                    </>
                }
                {/* <img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="logo" className="logo-w" /> */}
                <ToastContainer />
                <Switch>
                    <Route exact path="/:apiKey/:clinicId" component={Widget} />
                </Switch>
            </>
        );
    }
}
const mapStateToProps = (state: IAppState) => ({
    loadingState: state.loading,
});
const mapDispatchToProps = (dispatch: any) => ({

});
export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);